import { render, staticRenderFns } from "./OrderItemPrice.vue?vue&type=template&id=a1f5e08e&scoped=true&functional=true"
import script from "./OrderItemPrice.vue?vue&type=script&lang=js"
export * from "./OrderItemPrice.vue?vue&type=script&lang=js"
import style0 from "./OrderItemPrice.vue?vue&type=style&index=0&id=a1f5e08e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "a1f5e08e",
  null
  
)

export default component.exports