<template>
  <v-card elevation="0" class="order-detail-info">
    <v-row no-gutters align="center">
      <v-col cols="12" sm="6">
        <v-row
          no-gutters
          class="text-body-2 default--text font-weight-bold my-5"
        >
          <v-col cols="6" md="4">
            <span>{{ $t("orders.header.orderNumber") }}</span>
          </v-col>
          <v-col cols="6" md="3">
            <span>{{ $t("orders.header.status") }}</span>
          </v-col>
          <v-col cols="12" md="5"> </v-col>
        </v-row>
        <v-row no-gutters class="text-body-1 font-weight-bold my-5">
          <v-col cols="6" md="4">
            <span class="primary--text">#{{ order.orderId }}</span>
          </v-col>
          <v-col cols="6" md="3">
            <OrderStatusBadge v-bind:order="order" class="default--text" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" class="actions d-flex justify-end">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center justify-sm-end pa-0 align-center mt-5"
        >
          <OrderQrCode
            v-if="order.orderInfos.PIN_CODE"
            :codeValue="order.orderInfos.PIN_CODE"
            :width="40"
            :height="40"
          />

          <div
            class="d-flex flex-column justify-center align-center mr-5"
            v-if="order.isEditable"
          >
            <v-btn
              @click.stop.prevent="editOrder(order.orderId)"
              class="primary white--text"
              depressed
            >
              <v-icon>$edit</v-icon>
            </v-btn>
            <span class="primary--text text-center small">{{
              $t("orders.button.edit")
            }}</span>
          </div>

          <div
            class="d-flex flex-column justify-center align-center mr-5"
            v-if="order.isPayable"
          >
            <v-btn
              @click.stop.prevent="showPayment"
              class="primary white--text"
              depressed
            >
              <v-icon>$creditcard</v-icon>
            </v-btn>
            <span class="primary--text text-center small">{{
              $t("orders.button.retryPayment")
            }}</span>
          </div>

          <div class="d-flex flex-column justify-center align-center mr-5">
            <v-btn
              @click.stop.prevent="addAllToCart(order.orderId)"
              class="primary white--text"
              depressed
            >
              <v-icon>$cart</v-icon>
            </v-btn>
            <span class="primary--text text-center text-nowrap small">{{
              $t("orders.buttons.reorderOrder")
            }}</span>
          </div>

          <div class="d-flex flex-column justify-center align-center mr-5">
            <v-btn @click="printOrder" class="primary white--text" depressed>
              <v-icon>$print</v-icon>
            </v-btn>
            <span class="primary--text text-center small">{{
              $t("orders.button.print")
            }}</span>
          </div>
          <div
            class="d-flex flex-column justify-center align-center  ml-4"
            v-if="
              isCordova &&
                (order.orderStatusId == 8 || order.orderStatusId == 10)
            "
          >
            <v-btn
              @click.stop.prevent="addAllToCart(order.orderId)"
              class="primary white--text"
              v-if="order.orderStatusId == 8 || order.orderStatusId == 10"
            >
              <v-icon>$cart</v-icon>
            </v-btn>
            <span class="primary--text text-center small">{{
              $t("orders.button.reorder")
            }}</span>
          </div>

          <div
            class="d-flex flex-column justify-center align-center"
            v-if="order.isDeletable"
          >
            <v-btn
              color="error"
              @click.stop.prevent="deleteOrder(order.orderId)"
              depressed
            >
              <v-icon>$delete</v-icon>
            </v-btn>
            <span class="error--text text-center small text-nowrap">
              {{ $t("orders.button.delete") }}
            </span>
          </div>
        </v-col>
      </v-col>
    </v-row>
  </v-card>
</template>
<style lang="scss">
.order-detail-info {
  .small {
    font-size: 10px;
    min-height: 16px;
    text-transform: uppercase;
  }
}
</style>
<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge.vue";
import OrderQrCode from "./OrderQrCode.vue";
import downloadDocumentMixin from "~/mixins/downloadDocument";
export default {
  name: "OrderDetailInfo",
  mixins: [downloadDocumentMixin],
  components: { OrderStatusBadge, OrderQrCode },
  props: ["order"],
  computed: {
    receiptUrl() {
      let documentTypeId = 12;
      let orderId = this.order.orderId;
      return `/ebsn/api/order/document?document_type_id=${documentTypeId}&type=pdf&order_id=${orderId}`;
    }
  },
  methods: {
    addAllToCart(orderId) {
      this.$emit("addAllToCart", {
        orderId
      });
    },
    deleteOrder(orderId) {
      this.$emit("deleteOrder", {
        orderId
      });
    },
    editOrder(orderId) {
      this.$emit("editOrder", {
        orderId
      });
    },
    printOrder() {
      if (this.isCordova) {
        window.cordova.plugins.printer.print();
      } else {
        window.print();
      }
    },
    showPayment() {
      this.$emit("showPayment");
    }
  }
};
</script>
