<template>
  <div class="qty-box order-detail-quantity">
    <!-- <span class="qnt">{{ item.quantity }}</span> -->
    <span class="val">
      <!-- {{ delivered ? item.deliveredQuantity : quantity }} {{ unit }} -->
      {{ quantity }} {{ unit }}
    </span>
    <span class="small">{{ quantityPerUnit }}</span>
  </div>
</template>
<style lang="scss">
.qty-box.order-detail-quantity {
  width: 50%;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  white-space: nowrap;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    color: white;
    background: $primary;
    font-weight: 600;
    font-size: 14px;
    width: 110px;
    padding: 2px 5px;
  }
  .small {
    margin-left: 3px;
  }
}
</style>
<script>
import productMixin from "~/mixins/product";

export default {
  name: "OrderDetailQuantity",
  props: {
    item: { type: Object, required: true }
    // delivered: { type: Boolean, default: false }
  },
  mixins: [productMixin],
  computed: {
    product() {
      return this.item.product;
    }
  }
};
</script>
